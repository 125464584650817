import React from "react";

const Resume = () => {
    return(
        <div>
            <p>Here is your Resume</p>
        </div>
    )
};

export default Resume;